import * as React from 'react';
import styledComponents from 'styled-components';
import { Layout } from '../../components/layout';
import Banner from '../../components/banners';
import Treatments from '../../components/treatments';
import GrayRing from '../../images/Circulo-Prateado.png';

const TopContainer = styledComponents.div`
  background: transparent linear-gradient(180deg, #FFFFFF00 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  padding-bottom: 40px;
  margin-bottom: 0;

  background-image: url(${GrayRing});
  background-position: calc(50% + 700px) 650px;
  background-repeat: no-repeat;
  background-size: 800px 800px;
`;

const meta = [
  <meta key='description' name='description' content={'Clínica Dermatológica Angelucci - Dermatologia Cirúrgica, Clínica, Estética e Tratamentos Capilares.'} />,
];

const TreatmentsPage = () => (
  <Layout title={'Clínica Dermatológica Angelucci - Tratamentos'} meta={meta}>
    <main>
      <TopContainer>
        <Banner name='tratamentos'>a sua saúde merece o melhor em<strong>tratamentos</strong></Banner>
        <Treatments secondary/>
      </TopContainer>
    </main>
  </Layout>
);

export default TreatmentsPage;

import React from 'react';
import styledComponents from 'styled-components';
import { ContentDivisor } from '../divisor';
import { H2 } from '../titles';
import { PrimaryLinkButton, SecondaryLinkButton } from '../button';
import treatmentIcons from '../icons/treatmentIcons';
import THEME from '../../themes';

const TreatmentsContainer = styledComponents.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const TreatmentsCard = styledComponents.div`
  max-width: 270px;
  width: 100%;
  text-align: center;
  text-align: center;
  font: normal normal normal 16px/26px PT Sans;
  color: ${THEME.TEXT.BODY.COLOR};
  letter-spacing: 0.48px;
  margin: 0 10px 30px 10px;

  p {
    padding: 0 10px;
  }
`;

const TreatmentsIcon = styledComponents.img`
  width: 154px;
  margin: 0 auto;
`;

const TreatmentsTitle = styledComponents.p`
  color: ${THEME.COLORS.PRIMARY};
  text-transform: uppercase;
  padding-bottom: 0;
  margin-bottom: -15px;

  strong {
    font: normal normal bold 22px/26px PT Sans;
  }
`;

const createLinkTo = (route, isSecondayStyle) => {
  if (isSecondayStyle) {
    return <SecondaryLinkButton to={route}>Visualizar</SecondaryLinkButton>;
  }

  return <PrimaryLinkButton to={route}>Saiba mais</PrimaryLinkButton>;
};

// eslint-disable-next-line react/prop-types
const Treatments = ({ secondary = false }) => (
  <>
    <ContentDivisor />
    <H2>Tratamentos</H2>
    <TreatmentsContainer>
      <TreatmentsCard>
        <TreatmentsIcon src={treatmentIcons.iconClinica} alt="ícone dermatologia clínica"/>
        <br />
        <TreatmentsTitle>dermatologia<br /><strong>Clínica</strong></TreatmentsTitle>
        <p>
          Tem como objetivo diagnosticar, prevenir e tratar doenças
          que acometem a pele, cabelos e unhas.
        </p>
        {createLinkTo('/tratamentos/dermatologia_clinica', secondary)}
      </TreatmentsCard>
      <TreatmentsCard>
        <TreatmentsIcon src={treatmentIcons.iconCirurgica} alt="ícone dermatologia cirúrgica"/>
        <br />
        <TreatmentsTitle>dermatologia<br /><strong>cirúrgica</strong></TreatmentsTitle>
        <p>
          Procedimentos cirúrgicos e estéticos para prevenir, restaurar
          e manter a saúde da pele, cabelo e unhas.
        </p>
        {createLinkTo('/tratamentos/dermatologia_cirurgica', secondary)}
      </TreatmentsCard>
      <TreatmentsCard>
        <TreatmentsIcon src={treatmentIcons.iconEstetica} alt="ícone dermatologia estética"/>
        <br />
        <TreatmentsTitle>dermatologia<br /><strong>estética</strong></TreatmentsTitle>
        <p>
          Tratamentos estéticos faciais, corporais e capilares no mais
          alto nível de excelência em saúde, beleza e bem-estar.
        </p>
        {createLinkTo('/tratamentos/dermatologia_estetica', secondary)}
      </TreatmentsCard>
      <TreatmentsCard>
        <TreatmentsIcon src={treatmentIcons.iconCapilar} alt="ícone tratamentos capilares"/>
        <br />
        <TreatmentsTitle>tratamentos<br /><strong>capilares</strong></TreatmentsTitle>
        <p>
          Alta tecnologia associada a diagnósticos e procedimentos
          capazes de prevenir, tratar e manter a saúde capilar.
        </p>
        {createLinkTo('/tratamentos/tratamentos_capilares', secondary)}
      </TreatmentsCard>
    </TreatmentsContainer>
  </>
);

export default Treatments;
